<template>
	<div class="wrap">
		<img class="ref-title" src="../assets/img/ref-title.png" alt="">
		<img class="ref-line" src="../assets/img/list_line.png" alt="">
		<div class="ref-list" v-html="refInfo"></div>
		<img class="ref-bg" src="../assets/img/ref-bg.png" alt="">
	</div>
</template>

<script>
export default {
	data() {
		return {
			refInfo: ""
		}
	},
	created () {
		this.refInfo = localStorage.getItem('mankeRef')
	},
	mounted() {
		this.$wxShare._wxHideMenu()
	},
	methods: {
	},
	components: {

	}
}
</script>

<style scoped lang="scss">
.wrap{
	.ref-title{
		width: 298px;
		margin: auto;
		margin-top: 110px;
	}

	.ref-line{
		width: 80%;
		margin: auto;
		margin-top: 20px;
	}

	.ref-list{
		width: 80%;
		margin: auto;
		font-size: 24px!important;

		/deep/ li{
			font-size: 24px!important;
			margin-top: 30px;
			display: flex;

			&:before{
				content: '';
				width: 10px;
				height: 10px;
				background: #49FCFC;
				border-radius: 50%;
				flex-shrink: 0;
				margin-right: 10px;
				margin-top: 10px;
			}
		}
	}

	.ref-bg{
		width: 96%;
		position: absolute;
		bottom: 120px;
		right: 0;
	}
}
</style>
